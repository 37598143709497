// Workaround to bugfix the broken close icons in the flash messages
document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', (event) => {
    if (event.target.matches('[data-dismiss="alert"]')) {
      event.preventDefault();
      const alert = event.target.closest('.alert');
      if (alert) {
        alert.remove();
      }
    }
  });
});
